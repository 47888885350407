import styled, { css } from "styled-components/macro";
import {
  colorWhite,
  colorBlack,
  colorDarkGrey,
  colorYellow,
  colorDarkBlue,
  colorYellowHover,
  colorDarkGreyHover,
  colorPink,
  colorYellow2,
  colorLightGrey,
  colorGrey,
} from "../../../utils/css/colors";
import { mobile } from "../../../utils/css/media";
import ButtonSmall from "../../common/ButtonSmall";
import { Link } from "react-router-dom";
import { boldFont, bodyFont, lightFont } from "../../../utils/css/fonts";
import {
  body12,
  body14,
  body16,
  logoHeader,
  h6,
} from "../../../utils/css/typography";
import Checkbox from "../../common/Input/CheckBox";
import LoginInput from "../Input";
import PasswordInput from "../Input/PasswordInput";
import { FormContainer as RegisterForm } from "../Register/registerStyle";
import { Dialog } from "@material-ui/core";

// @ts-ignore
export const Form = styled(RegisterForm)`
  margin-top: 3rem;

  > div {
    margin-bottom: 4rem;
    ${mobile(css`
      margin-bottom: 3rem;
  `)};
  }

  > .loginStyle__ButtonContainer-sc-pbyb9r-7 {
    margin: auto 1rem 3rem auto;
  }

  ${mobile(css`
    width: 100%;
  `)};
`;

export const TitleForm = styled.div`
  margin-top: 60px;
  font-family: ${lightFont};
  font-size: 3rem;

  ${mobile(css`
    margin-top: 20px;
  `)};
`;

export const BottomTitleForm = styled.div`
  margin-top: 25px;
  font-family: ${lightFont};
  font-size: 3rem;

  ${mobile(css`
    margin-top: 20px;
  `)};
`;



export const BackToLogin = styled.div`
  color: ${colorBlack};
  text-decoration: underline;
  margin-top: 20px;
  margin-right: 90px;
  left: 90px;
  cursor: pointer;
`;

export const SubTitleForm = styled.div`
  font-family: ${lightFont};
  margin-top: 7px;
  font-size: 1.5rem;
`;

export const BlackLine = styled.div`
  width: 12.8rem;
  border-bottom: 1px solid #000;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${mobile(css`
    width: 100%;
  `)};
`;

export const SocialButtonsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  width: 308px;
  justify-content: center;

  ${mobile(css`
    width: 100%;
  `)};
`;

export const FormContainer = styled.form<{
  justifyContent?: string;
  minHeight?: string;
  minWidth?: string;
  padding?: string;
  maxWidth?: string;
}>`
  display: flex;
  z-index: 100;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "unset"};
  background: ${colorWhite};
  padding: ${({ padding }) => (padding ? padding : "3rem 6rem 1rem")};
  max-width: 570px;
  height: 100%;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "unset")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "unset")};
  width: 100%;

  ${mobile(css`
    width: 100%;
    max-width: unset;
    padding: 0 20px 0px;
  `)};
`;

export const Login = styled(LoginInput)`
  .MuiInputBase-root {
    &::before,
    &::after {
      width: 100%;
    }
  }
`;

export const Password = styled(PasswordInput)`
  .MuiInputBase-root {
    &::before,
    &::after {
      width: 100%;
    }
  }
`;

export const MiddleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ForgotPassword = styled(Link)`
  color: ${colorBlack};
  text-decoration: underline;
`;

export const SeperatorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1.5rem;
`;

export const ButtonContainer = styled.div`
  &.buttonContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  > :last-child {
    margin-top: 1.2rem;
  }

  ${mobile(css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-direction: column;
    > :last-child {
      margin-top: 0;
    }
  `)};
`;

export const LoginButton = styled(ButtonSmall)<{
  padding?: string;
  margin?: string;
  maxWidth?: string;
}>`
  padding: ${({ padding }) => (padding ? padding : "1.4rem")};
  margin: ${({ margin }) => margin && margin};
  background-color: ${colorYellow2};
  color: ${colorDarkGrey};
  width: 300px;
  height: 4.8rem;
  ${body14};
  font-family: ${bodyFont};
  width: 100%;
  font-size: 18px;

  :hover {
    background: ${colorYellowHover};
  }

  ${mobile(css`
    margin: 0;
    width: 100%;
  `)};
`;

export const LoginWithSmsBtn = styled(LoginButton)`
  height: 4.8rem;
`;

export const Seperator = styled.div`
  border: 0.5px solid ${colorBlack};
  margin: 0 5px;
  width: 77%;
  opacity: 0.2;
`;

export const LoginFooter = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 6%;
  margin-bottom: 5%;

  & > button {
    :not(:last-child) {
      margin-inline-end: 1.6rem;
      ${mobile(css`
        margin-inline-end: 1.2rem;
      `)};
    }
    margin-bottom: 1rem;
  }

  ${mobile(css`
    flex-wrap: nowrap;
    margin-bottom: 0;
  `)};
`;

const socialMediaButton = css`
  font-size: ${body12};
  padding: 0.5rem 1rem;

  ${mobile(css`
    width: 14rem;
    padding: 0.5rem 0.7rem;
    font-size: 1.2rem;
    font-family: ${bodyFont};
  `)};
`;

export const GoogleButton = styled(ButtonSmall)`
  ${socialMediaButton};
`;

export const FacebookButton = styled(ButtonSmall)`
  ${socialMediaButton};
  background-color: ${colorDarkBlue};
`;

export const CompanyLogo = styled.img`
  margin-inline-end: 4px;

  ${mobile(css`
    margin-inline-end: 2px;
  `)};
`;

export const GoToRegisterText = styled.p`
  font-size: 1.6rem;
  margin-top: 2rem;
  ${mobile(css`
    font-size: 1.4rem;
    align-self: center;
    margin-right: 0;
  `)};
`;

export const GoToRegisterLink = styled(Link)`
  color: ${colorBlack};
  text-decoration: underline;
`;

export const StyledCheckBox = styled(Checkbox)`
  margin: 0;
`;

export const Logo = styled.div`
  ${logoHeader};
  font-family: ${lightFont};
  font-size: 4.2rem;
  width: 245px;
  margin-inline-end: 10%;
  letter-spacing: 1.5px;

  ${mobile(css`
    font-size: 28px;
    width: 100%;
    margin-left: unset;
    padding: 0 20px;
  `)};
`;

export const MobileContactFooter = styled.div`
  width: 100%;
  height: 34px;
  background-color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const MobileContactLink = styled.a<{ direction: string }>`
  color: #ffffff;
  text-decoration: underline;
  margin: ${(props) =>
    props.direction === "rtl" ? "0 5px 0 0 " : "0 0 0 5px"};
`;

export const Modal = styled(Dialog)`
  &.MuiDialog-root {
    .MuiBackdrop-root {
      background-color: rgba(196, 196, 196, 0.8);
    }
  }
  .MuiPaper-root {
    height: 40.8rem;
    min-width: 62.8rem;
    top: -11rem;
    border-radius: 0.6rem;
    direction: rtl;
    @media (max-width: 768px) {
      min-width: 32.8rem;
    }
  }
`;

export const ModalContent = styled.div`
  height: 40.8rem;
  width: 62.8rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 32.8rem;
  }
`;

export const ModalTitle = styled.div<{ lng: string }>`
  ${h6};
  direction: ${({ lng }) => (lng ? (lng === "en" ? "ltr" : "rtl") : "rtl")};
  font-weight: bold;
  text-align: center;
  margin-top: 4.6rem;
  margin-bottom: 1rem;
`;

export const ModalSubtitle = styled.div<{ lng: string }>`
  ${body16};
  direction: ${({ lng }) => (lng ? (lng === "en" ? "ltr" : "rtl") : "rtl")};
  line-height: 2.2rem;
  margin: 1rem 0;
  padding: 2rem 4rem 5.1rem;
`;

// @ts-ignore
export const RoleButton = styled.button`
  color: ${colorPink};
  text-decoration: underline;
  border: none;
  background: none;
  cursor: pointer;
`;

export const GoogleSignButton = styled.button`
  background: ${colorWhite};
  border: 0.8px solid ${colorBlack};
  border-radius: 23px;
  height: 36px;
  width: 100%;
  font-family: ${bodyFont};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colorGrey};
  gap: 3px;
  cursor: pointer;
`;

export const FacebookSignButton = styled.button`
  background: ${colorDarkBlue};
  border-radius: 23px;
  border: none;
  height: 36px;
  width: 146px;
  font-family: ${bodyFont};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colorWhite};
  gap: 5px;
`;
