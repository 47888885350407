import { instance as axios } from "../middleware/auth.middleware";

const hostsUrl = "/api/hosts";
const hostSalonUrl = "/api/host_salons";
const hostSalonListUrl = "/api/host_salons_list";

// Host

export const getHostProfileCall = (hostId: string) => {
  return axios.get(`${hostsUrl}/${hostId}/`);
};

// Get user details
export const getUserDetails = async () => {
  try {
    const token = localStorage.getItem('access_token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    const response = await axios.get('/api/user_data/igul_le_tova/', {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 200) {
      const userDetails = response.data;
      console.log("api",userDetails.igul_le_tova);
      return userDetails.igul_le_tova;
    } else {
      console.error('Failed to fetch user details');
      return null;
    }
  } catch (error) {
    console.error('Error fetching user details:', error);
    return null;
  }
};

// Mark igul_le_tova
export const markIgulLeTova = () => {
  return axios.post('/api/user_data/mark_igul_le_tova/');
};

//Get host list
export const getHostsListCall = (params) => {
  return axios.get(`${hostsUrl}/`, { params });
};

export const updateHostProfileCall = (hostId: string, data: any) => {
  return axios.patch(`${hostsUrl}/${hostId}/`, data);
};

export const createHostCall = (data: any) => {
  return axios.post(`${hostsUrl}/`, data);
};

//Get all salons list

export const getAllSalonsListCall = (params) => {
  if (params) return axios.get(`${hostSalonUrl}_list/`, { params });
  else return axios.get(`${hostSalonListUrl}/?pagination=false`);
};

// Host salons

export const getHostSalonsSummaryCall = (hostId: string) => {
  return axios.get(`${hostsUrl}/${hostId}/summaries/`);
};

//here
export const getHostSalonByIdCall = (hostId: any) => {
  return axios.get(`${hostSalonUrl}/${hostId}/`);
};

export const checkChangeHour = (hostId: number, eventTime: string) => {
  return axios.post(`${hostSalonUrl}/${hostId}/check_time_range/`, { event_time: eventTime });
};

export const getHostSalonByHostIdCall = (hostId: number) => {
  return axios.get(`${hostSalonUrl}/`, { params: { host: hostId } });
};

export const getHostSalonCommentsCall = (salonId: number) => {
  return axios.get(`${hostSalonUrl}/${salonId}/comments/`);
};

export const addNewHostSalonCommentCall = (salonId: number, data: any) => {
  return axios.post(`${hostSalonUrl}/${salonId}/comments/`, data);
};

export const deleteWitnessTeamCommentCall = (
  salonId: string | number,
  commentId: number
) => {
  return axios.delete(`${hostSalonUrl}/${salonId}/comments/${commentId}/`);
};

//here
export const updateHostSalonCall = (salonId: number, data: any) => {
  return axios.patch(`${hostSalonUrl}/${salonId}/`, data);
};

export const addnNewHostSalonCall = (data: any) => {
  return axios.post(`${hostSalonUrl}/`, data);
};

export const getSalonsCall = (params) => {
  return axios.get(`${hostSalonUrl}_list/`, { params });
};

export const getHostSalonsListForTeaming = (params) => {
  return axios.get(`${hostSalonUrl}_teaming/`, { params });
};

export const getHostSalonTeamingDetails = (id: number) => {
  return axios.get(`${hostSalonUrl}_teaming/${id}/`);
};

export const getRelevantWitnessesForTeaming = (
  hostSalonId: number,
  cityParams?: any,
  nextPaginationPage?: number
) => {
  return axios.get(
    `${hostSalonUrl}/${hostSalonId}/relevant_witnesses/${
      nextPaginationPage
        ? `?page=${nextPaginationPage}&pagination=true`
        : "?pagination=true"
    }`,
    {
      params: cityParams,
    }
  );
};

export const getGuestRequestsCall = (hostSalonId) => {
  return axios.get(`api/salonguests/?salon=${hostSalonId}`);
};

export const deleteHostSalonByIdCall = (hostSalonId: number) => {
  return axios.delete(`${hostSalonUrl}/${hostSalonId}/`);
};

//Get all Hosts list For export

export const getAllHostsListCall = (params) => {
  if (params) return axios.get(`${hostsUrl}/`, { params });
  else return axios.get(`${hostsUrl}/?pagination=false`);
};

// Get host salon training

export const getHostSalonTrainingList = () => {
  return axios.get(`/api/host_salon_training/`);
};

// Delete host profile
export const deleteHostProfileCall = (hostId: any) => {
  return axios.delete(`${hostsUrl}/${hostId}/`);
};
